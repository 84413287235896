const photoData = [
  { id: 1, category: 'abstract', url: "https://res.cloudinary.com/dajuvlxyu/image/upload/v1720014599/abstract1_bx983g.jpg", subtitle: '' },
  { id: 2, category: 'abstract', url: "https://res.cloudinary.com/dajuvlxyu/image/upload/v1720014599/abstract2_wpzs2a.jpg", subtitle: '' },
  { id: 3, category: 'abstract', url: "https://res.cloudinary.com/dajuvlxyu/image/upload/v1720014599/abstract3_zfnobq.jpg", subtitle: '' },
  { id: 4, category: 'abstract', url: "https://res.cloudinary.com/dajuvlxyu/image/upload/v1720014599/abstract4_p9gas2.jpg", subtitle: '' },
  { id: 5, category: 'abstract', url: "https://res.cloudinary.com/dajuvlxyu/image/upload/v1720014599/abstract5_mtai3i.jpg", subtitle: '' },
  { id: 6, category: 'abstract', url: "https://res.cloudinary.com/dajuvlxyu/image/upload/v1720014599/abstract6_tts8up.jpg", subtitle: '' },
  { id: 7, category: 'abstract', url: "https://res.cloudinary.com/dajuvlxyu/image/upload/v1720014600/abstract7_xycou5.jpg", subtitle: '' },
  { id: 8, category: 'abstract', url: "https://res.cloudinary.com/dajuvlxyu/image/upload/v1720014600/abstract8_ak37rh.jpg", subtitle: '' },
  { id: 1, category: 'landscape', url: "https://res.cloudinary.com/dajuvlxyu/image/upload/v1720014601/landscape1_sbq43t.jpg", subtitle: '' },
  { id: 2, category: 'landscape', url: "https://res.cloudinary.com/dajuvlxyu/image/upload/v1720014600/landscape2_umvlby.jpg", subtitle: '' },
  { id: 3, category: 'landscape', url: "https://res.cloudinary.com/dajuvlxyu/image/upload/v1720014600/landscape3_kxvqqt.jpg", subtitle: '' },
  { id: 4, category: 'landscape', url: "https://res.cloudinary.com/dajuvlxyu/image/upload/v1720014601/landscape4_prnskd.jpg", subtitle: '' },
  { id: 5, category: 'landscape', url: "https://res.cloudinary.com/dajuvlxyu/image/upload/v1720014602/landscape5_hujz1e.jpg", subtitle: '' },
  { id: 6, category: 'landscape', url: "https://res.cloudinary.com/dajuvlxyu/image/upload/v1720014602/landscape6_lzq7wd.jpg", subtitle: '' },
  { id: 7, category: 'landscape', url: "https://res.cloudinary.com/dajuvlxyu/image/upload/v1720014602/landscape7_qbehfc.jpg", subtitle: '' },
  { id: 8, category: 'landscape', url: "https://res.cloudinary.com/dajuvlxyu/image/upload/v1720014602/landscape8_a2vxbd.jpg", subtitle: '' },
  { id: 9, category: 'landscape', url: "https://res.cloudinary.com/dajuvlxyu/image/upload/v1720014602/landscape9_o44r85.jpg", subtitle: '' },
  { id: 10, category: 'landscape', url: "https://res.cloudinary.com/dajuvlxyu/image/upload/v1720014602/landscape10_kr0d73.jpg", subtitle: '' },
  { id: 11, category: 'landscape', url: "https://res.cloudinary.com/dajuvlxyu/image/upload/v1720014602/landscape11_vphmmf.jpg", subtitle: '' },
  { id: 12, category: 'landscape', url: "https://res.cloudinary.com/dajuvlxyu/image/upload/v1720014603/landscape12_wkee8f.jpg", subtitle: '' },
  { id: 13, category: 'landscape', url: "https://res.cloudinary.com/dajuvlxyu/image/upload/v1720014603/landscape13_fxew8q.jpg", subtitle: '' },
  { id: 1, category: 'monochrome', url: "https://res.cloudinary.com/dajuvlxyu/image/upload/v1720014603/monochrome1_qbzpqr.jpg", subtitle: '' },
  { id: 2, category: 'monochrome', url: "https://res.cloudinary.com/dajuvlxyu/image/upload/v1720014603/monochrome2_i7nvpd.jpg", subtitle: '' },
  { id: 3, category: 'monochrome', url: "https://res.cloudinary.com/dajuvlxyu/image/upload/v1720014603/monochrome3_fxaabj.jpg", subtitle: '' },
  { id: 4, category: 'monochrome', url: "https://res.cloudinary.com/dajuvlxyu/image/upload/v1720014603/monochrome4_rnjyx0.jpg", subtitle: '' },
  { id: 5, category: 'monochrome', url: "https://res.cloudinary.com/dajuvlxyu/image/upload/v1720014603/monochrome5_bixelg.jpg", subtitle: '' },
  { id: 6, category: 'monochrome', url: "https://res.cloudinary.com/dajuvlxyu/image/upload/v1720014603/monochrome6_tn1y7q.jpg", subtitle: '' },
  { id: 7, category: 'monochrome', url: "https://res.cloudinary.com/dajuvlxyu/image/upload/v1720014603/monochrome7_hjczye.jpg", subtitle: '' },
  { id: 8, category: 'monochrome', url: "https://res.cloudinary.com/dajuvlxyu/image/upload/v1720014604/monochrome8_yaisth.jpg", subtitle: '' },
  { id: 9, category: 'monochrome', url: "https://res.cloudinary.com/dajuvlxyu/image/upload/v1720014604/monochrome9_aglaot.jpg", subtitle: '' },
  { id: 10, category: 'monochrome', url: "https://res.cloudinary.com/dajuvlxyu/image/upload/v1720014604/monochrome10_jp87bx.jpg", subtitle: '' },
  { id: 11, category: 'monochrome', url: "https://res.cloudinary.com/dajuvlxyu/image/upload/v1720014604/monochrome11_oton6b.jpg", subtitle: '' },
  { id: 12, category: 'monochrome', url: "https://res.cloudinary.com/dajuvlxyu/image/upload/v1720014604/monochrome12_a0jcri.jpg", subtitle: '' },
  { id: 13, category: 'monochrome', url: "https://res.cloudinary.com/dajuvlxyu/image/upload/v1720014604/monochrome13_tclrgb.jpg", subtitle: '' },
  { id: 14, category: 'monochrome', url: "https://res.cloudinary.com/dajuvlxyu/image/upload/v1720014605/monochrome14_iwuzlg.jpg", subtitle: '' },
  { id: 15, category: 'monochrome', url: "https://res.cloudinary.com/dajuvlxyu/image/upload/v1720014605/monochrome15_sungp0.jpg", subtitle: '' },
  { id: 16, category: 'monochrome', url: "https://res.cloudinary.com/dajuvlxyu/image/upload/v1720014605/monochrome16_rdcbhv.jpg", subtitle: '' },
  { id: 1, category: 'people', url: "https://res.cloudinary.com/dajuvlxyu/image/upload/v1720014605/people1_nrhazj.jpg", subtitle: '' },
  { id: 2, category: 'people', url: "https://res.cloudinary.com/dajuvlxyu/image/upload/v1720014605/people2_ucgg0w.jpg", subtitle: '' },
  { id: 3, category: 'people', url: "https://res.cloudinary.com/dajuvlxyu/image/upload/v1720014605/people3_y20qot.jpg", subtitle: '' },
  { id: 4, category: 'people', url: "https://res.cloudinary.com/dajuvlxyu/image/upload/v1720014605/people4_ibxxt5.jpg", subtitle: '' },
  { id: 5, category: 'people', url: "https://res.cloudinary.com/dajuvlxyu/image/upload/v1720014606/people5_c3nhyn.jpg", subtitle: '' },
  { id: 7, category: 'studio', url: "https://res.cloudinary.com/dajuvlxyu/image/upload/v1720014606/portrait2_sx2kih.jpg", subtitle: '' },
  { id: 9, category: 'studio', url: "https://res.cloudinary.com/dajuvlxyu/image/upload/v1720014606/portrait4_bd1tfb.jpg", subtitle: '' },
  { id: 10, category: 'studio', url: "https://res.cloudinary.com/dajuvlxyu/image/upload/v1720156940/terri-23_ztkorv.jpg", subtitle: '' },
  { id: 13, category: 'studio', url: "https://res.cloudinary.com/dajuvlxyu/image/upload/v1720156989/terri-15_mfyppl.jpg", subtitle: '' },
  { id: 11, category: 'studio', url: "https://res.cloudinary.com/dajuvlxyu/image/upload/v1720156940/terri-24_phbhdk.jpg", subtitle: '' },
  { id: 12, category: 'studio', url: "https://res.cloudinary.com/dajuvlxyu/image/upload/v1720156995/terri-10_pzu8iz.jpg", subtitle: '' },
  { id: 14, category: 'studio', url: "https://res.cloudinary.com/dajuvlxyu/image/upload/v1720156941/terri-19_vmboqk.jpg", subtitle: '' },
  { id: 1, category: 'special_project', url: "https://res.cloudinary.com/dajuvlxyu/image/upload/v1721439902/Full_Res_Emotions_Collage_njj03n.jpg", subtitle: 'Navarasa - Emotions' },
  { id: 2, category: 'special_project', url: "https://res.cloudinary.com/dajuvlxyu/image/upload/v1721439926/Square_Full_Res-1_rtbpt9.jpg", subtitle: 'Hasya - Laughter' },
  { id: 3, category: 'special_project', url: "https://res.cloudinary.com/dajuvlxyu/image/upload/v1721439917/Square_Full_Res-2_rjqvkg.jpg", subtitle: 'Shantha - Peace' },
  { id: 4, category: 'special_project', url: "https://res.cloudinary.com/dajuvlxyu/image/upload/v1721439918/Square_Full_Res-3_zrwkfc.jpg", subtitle: 'Shringara - Love' },
  { id: 5, category: 'special_project', url: "https://res.cloudinary.com/dajuvlxyu/image/upload/v1721439922/Square_Full_Res-4_nhtna9.jpg", subtitle: 'Veera - Heroism' },
  { id: 6, category: 'special_project', url: "https://res.cloudinary.com/dajuvlxyu/image/upload/v1721439920/Square_Full_Res-5_nvbqpx.jpg", subtitle: 'Bhayanaka - Fear' },
  { id: 7, category: 'special_project', url: "https://res.cloudinary.com/dajuvlxyu/image/upload/v1721439925/Square_Full_Res-6_yk6yub.jpg", subtitle: 'Raudra - Anger' },
  { id: 8, category: 'special_project', url: "https://res.cloudinary.com/dajuvlxyu/image/upload/v1721439928/Square_Full_Res-7_almyph.jpg", subtitle: 'Adbutha - Surprise' },
  { id: 9, category: 'special_project', url: "https://res.cloudinary.com/dajuvlxyu/image/upload/v1721439929/Square_Full_Res-8_ahe3mm.jpg", subtitle: 'Bibhatsya - Disgust' },
  { id: 10, category: 'special_project', url: "https://res.cloudinary.com/dajuvlxyu/image/upload/v1721439929/Square_Full_Res-9_mbetpp.jpg", subtitle: 'Karuna - Sorrow' },
];

export default photoData;

